import { ICharacterData } from "./ICharacterData";

export enum Action {
  Stop,
  StartWalkingLeft,
  StartWalkingRight,
  StartRunningLeft,
  StartRunningRight,
  Jump,
}

export type ActionProbability = {
  action: Action;
  percentChance: number; // 0-100
};

export interface IMonsterCharacterData extends ICharacterData {
  // Must sum to < 100
  actionTable: ActionProbability[];
  visionDistance: number;
}
