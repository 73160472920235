import * as BABYLON from "@babylonjs/core";
import {
  gridXToWorldXBottomLeft,
  gridYToWorldYBottomLeft,
  worldUnitsPerGridUnitX,
  worldUnitsPerGridUnitY,
  worldXToGridX,
  worldYToGridY,
} from "../coords";
import { Rect } from "../utils";
import { ICharacterData } from "./data/ICharacterData";
import { FloatAwayText, getNewFloatAwayText } from "./floatAwayText";

const gravityA: number = 0.03;
const deathAnimationDurationMs: number = 2000;
const targetFrameRate = 60;
const deathAnimationDeltaA: number = (-1 * 1000) / (deathAnimationDurationMs * targetFrameRate);
enum CollisionType {
  None = 0,
  MapTile = 1,
  Character = 2,
}

export enum Direction {
  Left = 0,
  Right = 1,
}

export abstract class Character {
  private _spriteManager: BABYLON.SpriteManager;
  private _xLastValueWhenStepChanged: number = 0;
  protected readonly _sprite: BABYLON.Sprite;
  protected _currentVX: number = 0;
  protected _currentVY: number = 0;
  protected _isHoldingRunButton: boolean = false;
  protected _joystickPosition: number = 0;
  protected _floatAwayTexts: FloatAwayText[] = [];
  protected _facing: Direction = Direction.Left;
  protected _hitPoints: number = 0;
  protected _isDying: boolean = false;
  protected _forcedAlpha: number = 1;

  constructor(
    name: string,
    protected readonly _characterData: ICharacterData,
    private readonly _scene: BABYLON.Scene,
    protected readonly _camera: BABYLON.UniversalCamera,
    private readonly _collisionMap: boolean[][],
    protected readonly onDied: (character: Character) => void
  ) {
    this._spriteManager = new BABYLON.SpriteManager(
      `${name}_SpriteManager`,
      this._characterData.spriteSheetUrl,
      1,
      64,
      _scene
    );

    this._sprite = new BABYLON.Sprite(name, this._spriteManager);
    if (this._characterData.normalColor) {
      this._sprite.color = this._characterData.normalColor;
    }

    // Why do we need to do this to make it to scale to the terrain?
    this._sprite.size = 2;

    this._hitPoints = this._characterData.startingHitPoints;
  }

  public readonly boundingBox: Rect = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  private readonly boundingBoxPoints: BABYLON.Vector2[] = [];

  public getTilesCharacterIsTouching(): BABYLON.Vector2[] {
    return this.boundingBoxPoints.map((point) => new BABYLON.Vector2(worldXToGridX(point.x), worldYToGridY(point.y)));
  }

  public moveToGridPosition(gridX: number, gridY: number, centerCameraOnCharacter: boolean = false): void {
    this._sprite.position = new BABYLON.Vector3(
      gridXToWorldXBottomLeft(gridX) + (worldUnitsPerGridUnitX * this._characterData.gridWidth) / 2,
      gridYToWorldYBottomLeft(gridY) + (worldUnitsPerGridUnitY * this._characterData.gridHeight) / 2,
      -0.001
    );
    this._xLastValueWhenStepChanged = this._sprite.position.x;
    this._sprite.cellIndex = this._characterData.spriteIndexes.stopped;

    if (centerCameraOnCharacter) {
      this._camera.position.x = this._sprite!.position.x;
      this._camera.position.y = this._sprite!.position.y;
    }
  }

  public get gridPosition(): BABYLON.Vector2 {
    return new BABYLON.Vector2(
      worldXToGridX(this._sprite.position.x),
      worldYToGridY(
        this._sprite.position.y -
          (worldUnitsPerGridUnitY * this._characterData.gridHeight) / 2 -
          this._characterData.boundingBoxYMargin
      )
    );
  }

  public addFloatAwayText(text: string, color: string): void {
    const fat = getNewFloatAwayText(text, color, this._characterData.gridHeight / 2, this._scene);
    this._floatAwayTexts.push(fat);
    fat.onAnimationComplete = () => {
      const index = this._floatAwayTexts.indexOf(fat);
      this._floatAwayTexts.splice(index, 1);
    };
  }

  public tick(timeCorrectionFactor: number): void {
    // Calculate X velocity based on inputs
    if (this._isHoldingRunButton) {
      this._currentVX = this._characterData.runningVX * this._joystickPosition;
    } else {
      this._currentVX = this._characterData.walkingVX * this._joystickPosition;
    }

    // Do stopped animation
    if (this._currentVX === 0) {
      this._sprite!.cellIndex = this._characterData.spriteIndexes.stopped;
      this._xLastValueWhenStepChanged = this._sprite!.position.x;
    }

    // Flip sprite based on direction of travel
    if (this._currentVX !== 0) {
      this._sprite!.invertU = this._currentVX > 0;
      this._facing = this._sprite!.invertU ? Direction.Right : Direction.Left;
    }

    if (this.isCollidingWithMapTilesOrOtherCharacters() !== CollisionType.None) {
      debugger;
    }

    // Move in the x direction
    this._sprite!.position.x += this._currentVX * timeCorrectionFactor;
    this.updateBoundingBox();

    // Check for collision
    let collision = this.isCollidingWithMapTilesOrOtherCharacters();
    if (collision !== CollisionType.None) {
      this._sprite!.position.x -= this._currentVX * timeCorrectionFactor;
      this._sprite!.cellIndex = this._characterData.spriteIndexes.stopped;
      this._xLastValueWhenStepChanged = this._sprite!.position.x;
      this._currentVX = 0;
      this.updateBoundingBox();

      if (collision === CollisionType.MapTile) {
        this.hitHorizontalObstacle();
      }

      if (this.isCollidingWithMapTilesOrOtherCharacters() !== CollisionType.None) {
        debugger;
      }
    }

    // Walking animation
    if (
      Math.abs(this._sprite!.position.x - this._xLastValueWhenStepChanged) >= this._characterData.xDeltaBetweenSteps
    ) {
      this._sprite!.cellIndex =
        this._sprite!.cellIndex === this._characterData.spriteIndexes.walkEnd
          ? this._characterData.spriteIndexes.walkStart
          : this._sprite!.cellIndex + 1;
      this._xLastValueWhenStepChanged = this._sprite!.position.x;
    }

    // Apply gravity
    this._currentVY -= gravityA * timeCorrectionFactor;

    if (this.isCollidingWithMapTilesOrOtherCharacters() !== CollisionType.None) {
      debugger;
    }

    // Move in the Y direction
    this._sprite!.position.y += this._currentVY * timeCorrectionFactor;
    this.updateBoundingBox();

    // Check for collision
    if (this.isCollidingWithMapTilesOrOtherCharacters() !== CollisionType.None) {
      // TODO: Fix bug where this backoff is too aggressive (simulate with large timeCorrectionFactor by setting target framerate too high).
      //       In that case, this backs off too far and isCharacterOnGround will be false since when it should be true.
      //       We need to back off just enough to have the character just barely above the ground, not all the way back to the previous position.
      //       We probably need to do the same for X collisions.
      this._sprite!.position.y -= this._currentVY * timeCorrectionFactor;
      this._currentVY = 0;
      this.updateBoundingBox();

      if (this.isCollidingWithMapTilesOrOtherCharacters() !== CollisionType.None) {
        debugger;
      }

      // Force the alpha to the right
      this._sprite.color.a = this._forcedAlpha;
    }

    // Do the dying animation
    if (this._isDying) {
      this._forcedAlpha = Math.max(0, this._forcedAlpha + deathAnimationDeltaA);
      if (this._forcedAlpha <= 0.05) {
        this._sprite.dispose();
        this.onDied(this);
      }
    }

    // Jumping animation
    if (!this.isCharacterOnGround()) {
      this._sprite!.cellIndex = this._characterData.spriteIndexes.walkEnd;
    }

    // Align any FloatAwayTexts to the character
    for (const fat of this._floatAwayTexts) {
      fat.tick(this._sprite.position, timeCorrectionFactor);
    }
  }

  public jump(): void {
    if (this._currentVY === 0 && this.isCharacterOnGround()) {
      this._currentVY = this._characterData.jumpInitialVY;
    }
  }

  public smallJump(): void {
    if (this._currentVY === 0 && this.isCharacterOnGround()) {
      this._currentVY = this._characterData.smallJumpInitialVY;
    }
  }

  protected abstract isCollidingWithAnotherCharacter(x: number, y: number): boolean;

  private updateBoundingBox(): void {
    if (this._characterData.gridWidth !== 1 || this._characterData.gridHeight !== 2) {
      throw new Error("Assumption violated");
    }

    const shiftDownBy = 0;

    this.boundingBox.left =
      this._sprite!.position.x -
      (worldUnitsPerGridUnitX * this._characterData.gridWidth) / 2 -
      this._characterData.boundingBoxXMargin;

    this.boundingBox.right =
      this._sprite!.position.x +
      (worldUnitsPerGridUnitX * this._characterData.gridWidth) / 2 +
      this._characterData.boundingBoxXMargin;

    this.boundingBox.top =
      this._sprite!.position.y +
      (worldUnitsPerGridUnitY * this._characterData.gridHeight) / 2 +
      this._characterData.boundingBoxYMargin -
      shiftDownBy;

    this.boundingBox.bottom =
      this._sprite!.position.y -
      (worldUnitsPerGridUnitY * this._characterData.gridHeight) / 2 -
      this._characterData.boundingBoxYMargin -
      shiftDownBy;

    // Grab enough points that a block can't sneak inbetween them

    const verticalMiddle = this._sprite!.position.y;

    this.boundingBoxPoints.splice(0, this.boundingBoxPoints.length);
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.left, this.boundingBox.bottom));
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.right, this.boundingBox.bottom));
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.left, verticalMiddle));
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.right, verticalMiddle));
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.left, this.boundingBox.top));
    this.boundingBoxPoints.push(new BABYLON.Vector2(this.boundingBox.right, this.boundingBox.top));
  }

  private getBottomBoundingBoxPoints(shiftDownBy: number): BABYLON.Vector2[] {
    if (this._characterData.gridWidth !== 1 || this._characterData.gridHeight !== 2) {
      throw new Error("Assumption violated");
    }

    const left =
      this._sprite!.position.x -
      (worldUnitsPerGridUnitX * this._characterData.gridWidth) / 2 -
      this._characterData.boundingBoxXMargin;

    const right =
      this._sprite!.position.x +
      (worldUnitsPerGridUnitX * this._characterData.gridWidth) / 2 +
      this._characterData.boundingBoxXMargin;

    const bottom =
      this._sprite!.position.y -
      (worldUnitsPerGridUnitY * this._characterData.gridHeight) / 2 -
      this._characterData.boundingBoxYMargin -
      shiftDownBy;

    return [new BABYLON.Vector2(left, bottom), new BABYLON.Vector2(right, bottom)];
  }

  private isCollidingWithMapTilesOrOtherCharacters(): CollisionType {
    for (const point of this.boundingBoxPoints) {
      if (this.isInSolidMapTile(point.x, point.y)) {
        return CollisionType.MapTile;
      }
      if (this.isCollidingWithAnotherCharacter(point.x, point.y)) {
        return CollisionType.Character;
      }
    }

    return CollisionType.None;
  }

  private isCharacterOnGround(): boolean {
    const boundingBoxPoints = this.getBottomBoundingBoxPoints(this._characterData.yToleranceForTouchingGround);

    if (this._characterData.gridWidth !== 1) {
      throw new Error("Collision detection logic assumes this._characterData.gridWidth === 1");
    }

    for (const point of boundingBoxPoints) {
      if (this.isInSolidMapTile(point.x, point.y)) {
        return true;
      }
    }

    return false;
  }

  private isInSolidMapTile(x: number, y: number): boolean {
    if (x < 0 || x >= this._collisionMap.length || y < 0) {
      // Hit left, right, bottom of chunk
      return true;
    }

    if (y >= this._collisionMap[0].length) {
      // Hit top of chunk - need room to jump!
      return false;
    }
    return this._collisionMap[Math.floor(worldXToGridX(x))][Math.floor(worldYToGridY(y))];
  }

  protected hitHorizontalObstacle(): void {}

  public isCharacterTouchingPoint(x: number, y: number): boolean {
    return (
      x >= this.boundingBox.left &&
      x <= this.boundingBox.right &&
      y <= this.boundingBox.top &&
      y >= this.boundingBox.bottom
    );
  }

  public inflictDamage(amount: number) {
    if (!this._isDying) {
      this._hitPoints -= amount;
      if (this._hitPoints < 0) {
        this.addFloatAwayText(`-${amount} Dead!`, "red");
        this._isDying = true;
      } else {
        this.addFloatAwayText(`-${amount}`, "red");
      }
    }
  }
}
