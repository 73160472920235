import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import { Engine } from "@babylonjs/core";
import "./index.css";
import { GameManager } from "./gameManager";

class App {
  private _gameManager: GameManager | undefined;

  constructor() {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    // create the canvas html element and attach it to the webpage
    var canvas = document.createElement("canvas");
    canvas.className = "fullScreenCanvas";
    canvas.id = "gameCanvas";
    document.body.appendChild(canvas);

    // initialize babylon scene and engine
    var engine = new Engine(canvas, true);
    this._gameManager = new GameManager(canvas, engine);
    await this._gameManager.initialize();

    // run the main render loop
    const scene = this._gameManager.scene;
    engine.runRenderLoop(() => {
      this._gameManager!.tick();
      scene.render();
    });
    window.addEventListener("resize", function () {
      engine.resize();
    });
    window.addEventListener("keydown", (e) => {
      if (this._gameManager!.keyDown(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
    window.addEventListener("keyup", (e) => {
      if (this._gameManager!.keyUp(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
    canvas.addEventListener("click", (e) => {
      if (this._gameManager!.click(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
    canvas.addEventListener("pointerdown", (e) => {
      if (this._gameManager!.pointerDown(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
    canvas.addEventListener("pointerup", (e) => {
      if (this._gameManager!.pointerUp(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
    canvas.addEventListener("pointermove", (e) => {
      if (this._gameManager!.pointerMove(e)) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  }
}
new App();
