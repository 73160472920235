import { ICharacterData } from "../ICharacterData";

export const playerCharacterData: ICharacterData = {
  gridWidth: 1,
  gridHeight: 2,
  spriteIndexes: {
    stopped: 9,
    walkStart: 9,
    walkEnd: 17,
  },
  spriteSheetUrl: "./assets/goldCharacterSpriteSheet.png",
  walkingVX: 0.05,
  runningVX: 0.1,
  jumpInitialVY: 0.4,
  smallJumpInitialVY: 0.3,
  boundingBoxXMargin: -0.1,
  boundingBoxYMargin: -0.1,
  yToleranceForTouchingGround: 0.1,
  xDeltaBetweenSteps: 0.15,
  startingHitPoints: 2000,
};
