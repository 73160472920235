import * as BABYLON from "@babylonjs/core";
import { gridXToWorldXBottomLeft, gridYToWorldYBottomLeft } from "./coords";
import { SaveGame, TerrainSprite } from "./saveGame";
import { clamp } from "./utils";

enum MouseButton {
  None = 1,
  Left = 0,
  Right = 2,
}

// Constants
const minGrassLayerHeight = 0;
const maxGrassLayerHeight = 1;
const minDirtLayerHeight = 0;
const maxDirtLayerHeight = 20;
const chunkSize: BABYLON.Vector2 = new BABYLON.Vector2(200, 60);

export class TerrainManager {
  private _saveGame: SaveGame | undefined;
  private _spriteSheetTexture: BABYLON.Texture;
  private _spriteSheetAtlas: any;
  private _terrainSpriteMap: BABYLON.SpriteMap | undefined;
  private _collisionMap: boolean[][] = new Array<Array<boolean>>();
  private _isPointerDown: boolean = false;
  private _mouseButton: MouseButton = MouseButton.None;

  public get collisionMap(): boolean[][] {
    return this._collisionMap;
  }

  constructor(
    private readonly _scene: BABYLON.Scene,
    private readonly movePlayerToStartingPosition: (gridX: number, gridY: number) => void,
    private readonly getTilesPlayerTouching: () => BABYLON.Vector2[],
    private readonly forceSave: () => void
  ) {
    // Load the SpriteSheet Associated with the JSON Atlas.
    this._spriteSheetTexture = new BABYLON.Texture(
      "./assets/mapSpriteSheet.png",
      this._scene,
      false, //NoMipMaps
      false, //InvertY usually false if exported from TexturePacker
      BABYLON.Texture.NEAREST_NEAREST, //Sampling Mode
      null, //Onload, you could spin up the sprite map in a function nested here
      null, //OnError
      null, //CustomBuffer
      false, //DeleteBuffer
      BABYLON.Engine.TEXTUREFORMAT_RGBA //ImageFormageType RGBA
    );

    this._spriteSheetTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;
    this._spriteSheetTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE; //Or Wrap, its up to you...
  }

  public async initialize(): Promise<void> {
    let jsonFetch = await fetch("./assets/mapSpriteSheet.json");
    this._spriteSheetAtlas = await jsonFetch.json();
    this._terrainSpriteMap = new BABYLON.SpriteMap(
      "terrain",
      this._spriteSheetAtlas,
      this._spriteSheetTexture,
      {
        stageSize: chunkSize,
        maxAnimationFrames: 8,
        baseTile: TerrainSprite.Empty,
        layerCount: 2,
        flipU: true,
        //colorMultiply: new BABYLON.Vector3(0.6, 0.6, 0.6),
      },
      this._scene
    );
    this._terrainSpriteMap.position.x = gridXToWorldXBottomLeft(chunkSize.x) / 2;
    this._terrainSpriteMap.position.y = gridYToWorldYBottomLeft(chunkSize.y) / 2;
    this._terrainSpriteMap.position.z = 0;

    for (let x = 0; x < chunkSize.x; x++) {
      this._collisionMap.push(new Array<boolean>(chunkSize.y));
    }
  }

  public click(mouseEvent: MouseEvent): boolean {
    return false;
  }

  private getPointerXY(): BABYLON.Vector2 {
    const mousePositionOnTerrainByPercent = this._terrainSpriteMap!.getMousePosition();
    if (mousePositionOnTerrainByPercent.x === -1 || mousePositionOnTerrainByPercent.y === -1) {
      return new BABYLON.Vector2(-1, -1);
    }

    return new BABYLON.Vector2(
      Math.floor(mousePositionOnTerrainByPercent.x * chunkSize.x),
      Math.floor(mousePositionOnTerrainByPercent.y * chunkSize.y)
    );
  }

  public pointerDown(mouseEvent: MouseEvent): boolean {
    this._isPointerDown = true;
    this._mouseButton = mouseEvent.button;
    if (this._mouseButton === MouseButton.Left) {
      this.tryMine(this.getPointerXY());
    } else if (this._mouseButton === MouseButton.Right) {
      this.tryPlaceBlock(this.getPointerXY());
    }
    return true;
  }

  public pointerUp(mouseEvent: MouseEvent): boolean {
    this._isPointerDown = false;
    return true;
  }

  public pointerMove(mouseEvent: MouseEvent): boolean {
    if (this._isPointerDown) {
      if (this._mouseButton === MouseButton.Left) {
        this.tryMine(this.getPointerXY());
      } else if (this._mouseButton === MouseButton.Right) {
        this.tryPlaceBlock(this.getPointerXY());
      }
      return true;
    }
    return false;
  }

  private tryMine(blockPosition: BABYLON.Vector2): void {
    if (blockPosition.x === -1 || blockPosition.y === -1) {
      return;
    }
    let tilePositions = [new BABYLON.Vector2(blockPosition.x, blockPosition.y)];
    this._terrainSpriteMap!.changeTiles(0, tilePositions, TerrainSprite.Empty);
    this._collisionMap[blockPosition.x][blockPosition.y] = false;
    this._saveGame!.terrainDeltas.push({
      blockX: blockPosition.x,
      blockY: blockPosition.y,
      blockType: TerrainSprite.Empty,
    });
  }

  private characterOverlapsBlock(blockPosition: BABYLON.Vector2): boolean {
    const characterBlocks = this.getTilesPlayerTouching();
    for (const characterBlock of characterBlocks) {
      if (characterBlock.x === blockPosition.x && characterBlock.y === blockPosition.y) {
        return true;
      }
    }
    return false;
  }

  private tryPlaceBlock(blockPosition: BABYLON.Vector2): void {
    if (blockPosition.x === -1 || blockPosition.y === -1) {
      return;
    }
    if (
      this._collisionMap[blockPosition.x][blockPosition.y] === false &&
      this.characterOverlapsBlock(blockPosition) === false
    ) {
      let tilePositions = [new BABYLON.Vector2(blockPosition.x, blockPosition.y)];
      this._terrainSpriteMap!.changeTiles(0, tilePositions, TerrainSprite.Stone);
      this._collisionMap[blockPosition.x][blockPosition.y] = true;

      this._saveGame!.terrainDeltas.push({
        blockX: blockPosition.x,
        blockY: blockPosition.y,
        blockType: TerrainSprite.Stone,
      });
    }
  }

  private clearTerrain(): void {
    let tilePositions = [];
    for (let x = 0; x < chunkSize.x; x++) {
      const columnArray = this._collisionMap[x];
      for (let y = 0; y < chunkSize.y; y++) {
        columnArray[y] = false;
        tilePositions.push(new BABYLON.Vector2(x, y));
      }
    }
    this._terrainSpriteMap!.changeTiles(0, tilePositions, TerrainSprite.Empty);
  }

  public loadGame(saveGame: SaveGame): void {
    this._saveGame = saveGame;
    this.gerenateTerrainFromSaveGame(saveGame);
    this.applySavedGameDeltas(saveGame);
  }

  // Apply the deltas in the order they were recorded, batching like Sprite types together
  private applySavedGameDeltas(saveGame: SaveGame): void {
    let currentBlockType: TerrainSprite | undefined = undefined;
    const deltas = saveGame!.terrainDeltas;
    let tilePositions: BABYLON.Vector2[] = [];
    for (let deltaIndex = 0; deltaIndex < deltas.length; deltaIndex++) {
      const delta = deltas[deltaIndex];
      if (tilePositions.length > 0 && currentBlockType !== delta.blockType) {
        this._terrainSpriteMap!.changeTiles(0, tilePositions, currentBlockType);
        tilePositions.splice(0, tilePositions.length);
      }
      currentBlockType = delta.blockType;
      tilePositions.push(new BABYLON.Vector2(delta.blockX, delta.blockY));
      this._collisionMap[delta.blockX][delta.blockY] = currentBlockType !== TerrainSprite.Empty;
    }
    if (tilePositions.length > 0) {
      this._terrainSpriteMap!.changeTiles(0, tilePositions, currentBlockType);
      tilePositions.splice(0, tilePositions.length);
    }
  }

  private gerenateTerrainFromSaveGame(saveGame: SaveGame): void {
    this.clearTerrain();

    const grassTilesToPlace = [];
    const dirtTilesToPlace = [];
    const stoneTilesToPlace = [];

    let grassLayerHeight = Math.round(saveGame.getNextRandomNumber(minGrassLayerHeight, maxGrassLayerHeight));
    let dirtLayerHeight = Math.round(saveGame.getNextRandomNumber(minDirtLayerHeight, maxDirtLayerHeight));
    let totalHeight = Math.round(saveGame.getNextRandomNumber(grassLayerHeight + dirtLayerHeight, chunkSize.y));

    for (let x = 0; x < chunkSize.x; x++) {
      // Adjust grass layer height
      grassLayerHeight = clamp(
        minGrassLayerHeight,
        maxGrassLayerHeight,
        grassLayerHeight + Math.round(saveGame!.getNextRandomNumber(-1, 1))
      );

      // Adjust the dirt layer height
      dirtLayerHeight = clamp(
        minDirtLayerHeight,
        maxDirtLayerHeight,
        dirtLayerHeight + Math.round(saveGame!.getNextRandomNumber(-1, 1))
      );

      // Calculate total height
      totalHeight = clamp(
        grassLayerHeight + dirtLayerHeight,
        chunkSize.y,
        totalHeight + Math.round(saveGame!.getNextRandomNumber(-1, 1))
      );

      // grassLayerHeight = 0;
      // dirtLayerHeight = 0;
      // totalHeight = 1;

      // Draw the stone
      const stoneHeight = totalHeight - grassLayerHeight - dirtLayerHeight;
      for (let y = 0; y < stoneHeight; y++) {
        stoneTilesToPlace.push(new BABYLON.Vector2(x, y));
        this._collisionMap[x][y] = true;
      }

      // Draw the dirt
      for (let y = stoneHeight; y < stoneHeight + dirtLayerHeight; y++) {
        dirtTilesToPlace.push(new BABYLON.Vector2(x, y));
        this._collisionMap[x][y] = true;
      }

      // Draw the grass
      for (let y = stoneHeight + dirtLayerHeight; y < totalHeight; y++) {
        grassTilesToPlace.push(new BABYLON.Vector2(x, y));
        this._collisionMap[x][y] = true;
      }

      // If this was the center column, move the player to the top of it
      if (x === Math.floor(chunkSize.x / 2)) {
        if (saveGame.characterX === undefined) {
          saveGame.characterX = x;
          saveGame.characterY = totalHeight;
        }
      }
    }

    // Do the drawing
    this._terrainSpriteMap!.changeTiles(0, grassTilesToPlace, TerrainSprite.Grass);
    this._terrainSpriteMap!.changeTiles(0, dirtTilesToPlace, TerrainSprite.Dirt);
    this._terrainSpriteMap!.changeTiles(0, stoneTilesToPlace, TerrainSprite.Stone);
  }
}
