export const worldUnitsPerGridUnitX = 1;
export const worldUnitsPerGridUnitY = 1;

export function worldXToGridX(worldX: number): number {
  return Math.floor(worldX / worldUnitsPerGridUnitX);
}
export function worldYToGridY(worldY: number): number {
  return Math.floor(worldY / worldUnitsPerGridUnitY);
}
export function gridXToWorldXBottomLeft(gridX: number) {
  return gridX * worldUnitsPerGridUnitX;
}
export function gridYToWorldYBottomLeft(gridY: number) {
  return gridY * worldUnitsPerGridUnitY;
}
