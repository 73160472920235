import * as BABYLON from "@babylonjs/core";
import { randomNumber } from "../utils";
import { Character } from "./character";
import { Action, IMonsterCharacterData } from "./data/IMonsterCharacterData";
import { CharacterCollisionCheck, PlayerCharacter } from "./playerCharacter";

export class MonsterCharacter extends Character {
  constructor(
    name: string,
    private readonly _monsterData: IMonsterCharacterData,
    scene: BABYLON.Scene,
    camera: BABYLON.UniversalCamera,
    collisionMap: boolean[][],
    private readonly _playerCharacter: PlayerCharacter,
    private readonly isCollidingWithPlayer: CharacterCollisionCheck,
    onDied: (character: Character) => void
  ) {
    super(name, _monsterData, scene, camera, collisionMap, onDied);
  }

  public tick(timeCorrectionFactor: number): void {
    if (this._isDying) {
      this._joystickPosition = 0;
      this._isHoldingRunButton = false;
    } else {
      this.monsterTickBehavior(timeCorrectionFactor);
    }

    // Do all of the movement work
    super.tick(timeCorrectionFactor);
  }

  private monsterTickBehavior(timeCorrectionFactor: number): void {
    // Always chase the player first (and apply chasing color change)
    if (this.canSeePlayer()) {
      if (this._monsterData.chasingPlayerColor) {
        this._sprite.color = this._monsterData.chasingPlayerColor;
      }
      const dX = Math.floor(this._playerCharacter.gridPosition.x) - Math.floor(this.gridPosition.x);
      if (dX > 0) {
        this._joystickPosition = 1;
        this._isHoldingRunButton = true;
      } else if (dX < 0) {
        this._joystickPosition = -1;
        this._isHoldingRunButton = true;
      } else {
        this._joystickPosition = 0;
        this._isHoldingRunButton = false;
      }
    } else {
      if (this._monsterData.normalColor) {
        this._sprite.color = this._monsterData.normalColor;
      }
    }

    // Randomly change behavior
    if (!this._isDying) {
      let r = randomNumber(0, 100);
      for (const possibleAction of this._monsterData.actionTable) {
        if (r < possibleAction.percentChance * timeCorrectionFactor) {
          this.doAction(possibleAction.action);
          break;
        }
        r -= possibleAction.percentChance * timeCorrectionFactor;
      }
    }
  }

  protected isCollidingWithAnotherCharacter(x: number, y: number): boolean {
    return this.isCollidingWithPlayer(this, x, y);
  }

  private doAction(action: Action) {
    switch (action) {
      case Action.Stop:
        this._joystickPosition = 0;
        this._isHoldingRunButton = false;
        break;
      case Action.StartWalkingLeft:
        if (!this.canSeePlayer()) {
          this._joystickPosition = -1;
          this._isHoldingRunButton = false;
        }
        break;
      case Action.StartWalkingRight:
        if (!this.canSeePlayer()) {
          this._joystickPosition = 1;
          this._isHoldingRunButton = false;
        }
        break;
      case Action.StartRunningRight:
        if (!this.canSeePlayer()) {
          this._joystickPosition = 1;
          this._isHoldingRunButton = true;
        }
        break;
      case Action.StartRunningLeft:
        if (!this.canSeePlayer()) {
          this._joystickPosition = -1;
          this._isHoldingRunButton = true;
        }
        break;
      case Action.Jump:
        this.jump();
        break;
    }
  }

  private canSeePlayer(): boolean {
    const distanceToPlayer = Math.sqrt(
      Math.pow(Math.abs(this.gridPosition.x - this._playerCharacter.gridPosition.x), 2) +
        Math.pow(Math.abs(this.gridPosition.y - this._playerCharacter.gridPosition.y), 2)
    );
    return distanceToPlayer < this._monsterData.visionDistance;
  }

  protected hitHorizontalObstacle(): void {
    if (!this._isDying) {
      this.jump();
    }
  }
}
