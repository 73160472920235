import { randomNumber } from "./utils";

export const localStorageSaveData = "SavedTerrain";

export enum TerrainSprite {
  Empty = 0,
  Stone = 42,
  Grass = 43,
  Dirt = 44,
}

export type TerrainDelta = {
  blockX: number;
  blockY: number;
  blockType: TerrainSprite;
};

export type SaveGameData = {
  randomNumbers: number[];
  deltas: TerrainDelta[];
  characterX: number | undefined;
  characterY: number | undefined;
};

export class SaveGame {
  private _data: SaveGameData;
  private _currentNumber: number = -1;

  public get terrainDeltas(): TerrainDelta[] {
    return this._data.deltas;
  }

  public get characterX(): number | undefined {
    return this._data.characterX;
  }

  public set characterX(newValue: number | undefined) {
    this._data.characterX = newValue;
  }

  public get characterY(): number | undefined {
    return this._data.characterY;
  }

  public set characterY(newValue: number | undefined) {
    this._data.characterY = newValue;
  }

  constructor() {
    this._data = {
      randomNumbers: [],
      deltas: [],
      characterX: undefined,
      characterY: undefined,
    };
  }

  public tryLoad(): boolean {
    const savedDataString = window.localStorage.getItem(localStorageSaveData);
    try {
      if (savedDataString) {
        this._data = {
          randomNumbers: [],
          deltas: [],
          ...JSON.parse(savedDataString),
        };
        return true;
      }
    } catch {
      return false;
    }
    return false;
  }

  public getNextRandomNumber(min: number, max: number): number {
    this._currentNumber++;

    if (this._currentNumber === this._data.randomNumbers.length) {
      this._data.randomNumbers.push(randomNumber(min, max));
    }

    return this._data.randomNumbers[this._currentNumber];
  }

  public saveToLocalStorage(): void {
    window.localStorage.setItem(localStorageSaveData, JSON.stringify(this._data));
  }
}
