import * as BABYLON from "@babylonjs/core";
import { Action, IMonsterCharacterData } from "../IMonsterCharacterData";

export const redKnightCharacterData: IMonsterCharacterData = {
  gridWidth: 1,
  gridHeight: 2,
  spriteIndexes: {
    stopped: 9,
    walkStart: 9,
    walkEnd: 17,
  },
  spriteSheetUrl: "./assets/goldCharacterSpriteSheet.png",
  walkingVX: 0.02,
  runningVX: 0.05,
  jumpInitialVY: 0.4,
  smallJumpInitialVY: 0.3,
  boundingBoxXMargin: -0.1,
  boundingBoxYMargin: -0.1,
  yToleranceForTouchingGround: 0.1,
  xDeltaBetweenSteps: 0.15,
  normalColor: new BABYLON.Color4(0.75, 0, 0, 1),
  chasingPlayerColor: new BABYLON.Color4(1, 0, 0, 1),
  actionTable: [
    { action: Action.Stop, percentChance: 0.1 },
    { action: Action.StartWalkingLeft, percentChance: 0.1 },
    { action: Action.StartWalkingRight, percentChance: 0.1 },
    { action: Action.StartRunningLeft, percentChance: 0.1 },
    { action: Action.StartRunningRight, percentChance: 0.1 },
    { action: Action.Jump, percentChance: 0.2 },
  ],
  visionDistance: 10,
  startingHitPoints: 500,
};
