import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";

const pool: FloatAwayText[] = [];

export function getNewFloatAwayText(
  text: string,
  color: string,
  fixedYOffset: number,
  scene: BABYLON.Scene
): FloatAwayText {
  for (const fat of pool) {
    if (fat.isInUse === false) {
      fat.start(text, color, fixedYOffset);
      return fat;
    }
  }

  const newFat = new FloatAwayText(`FloatAwayText_${pool.length}`, scene);
  pool.push(newFat);
  newFat.start(text, color, fixedYOffset);
  return newFat;
}

const targetFrameRate = 60;
const animationDurationMs = 1250;
const finalDY = 2;
const dYPerTick = (finalDY * 1000) / (animationDurationMs * targetFrameRate);

const dAlphaPerTick = (-1 * 1000) / (animationDurationMs * targetFrameRate);

export class FloatAwayText {
  private _isInUse: boolean = false;
  private _plane: BABYLON.Mesh;
  private _textBlock: GUI.TextBlock;
  private _currentDY: number = 0;
  private _currentAlpha: number = 0;
  private _fixedYOffset: number = 0;

  public onAnimationComplete: (() => void) | undefined;

  constructor(name: string, private readonly _scene: BABYLON.Scene) {
    this._plane = BABYLON.Mesh.CreatePlane(`${name}_plane`, 2, _scene);
    var advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(this._plane, 150, 150);

    this._textBlock = new GUI.TextBlock();
    this._textBlock.fontStyle = "bold";
    this._textBlock.fontSize = 30;
    advancedTexture.addControl(this._textBlock);
  }

  public get isInUse(): boolean {
    return this._isInUse;
  }

  public start(text: string, color: string, fixedYOffset: number): void {
    if (this._isInUse) {
      debugger;
    }

    this._isInUse = true;
    this._currentDY = 0;
    this._fixedYOffset = fixedYOffset;
    this._currentAlpha = 1;
    this._textBlock.text = text;
    this._textBlock.color = color;
  }

  public tick(characterPosition: BABYLON.Vector3, timeCorrectionFactor: number): void {
    if (this._isInUse === false) {
      debugger;
    }
    this._plane.isVisible = true;
    this._currentDY += dYPerTick * timeCorrectionFactor;
    this._currentAlpha += dAlphaPerTick * timeCorrectionFactor;
    this._textBlock.alpha = this._currentAlpha;
    this._plane.position.x = characterPosition.x;
    this._plane.position.y = characterPosition.y + this._fixedYOffset + this._currentDY;
    this._plane.position.z = -0.002;

    if (this._currentDY >= finalDY) {
      this._isInUse = false;
      this._plane.isVisible = false;
      if (this.onAnimationComplete) {
        this.onAnimationComplete();
      }
    }
  }
}
