import * as BABYLON from "@babylonjs/core";
import { Character } from "./characters/character";
import { redKnightCharacterData } from "./characters/data/redKnight/redKnightCharacterData";
import { MonsterCharacter } from "./characters/monsterCharacter";
import { PlayerCharacter } from "./characters/playerCharacter";
import { SaveGame } from "./saveGame";
import { TerrainManager } from "./terrainManager";

const targetFrameRate = 60;

export class GameManager {
  private _camera: BABYLON.UniversalCamera;
  private _scene: BABYLON.Scene;
  private _player: PlayerCharacter;
  private _npcs: Character[];
  private _terrainManager: TerrainManager;
  private _saveGame: SaveGame;

  public get scene(): BABYLON.Scene {
    return this._scene;
  }

  constructor(private readonly canvas: HTMLCanvasElement, private readonly engine: BABYLON.Engine) {
    // Hardcode to always load the saved game (only one can be saved at a time right now)
    this._saveGame = new SaveGame();
    if (!this._saveGame.tryLoad()) {
      this._saveGame = new SaveGame();
    }

    // Create scene
    this._scene = new BABYLON.Scene(this.engine);

    // Create light
    new BABYLON.PointLight("Point", new BABYLON.Vector3(5, 10, 5), this._scene);

    // Create camera
    this._camera = new BABYLON.UniversalCamera("Camera", new BABYLON.Vector3(0, 0, -30), this._scene);
    this._camera.attachControl(this.canvas, true);

    // Configure the camere controls
    this._camera.inputs.removeMouse();
    this._camera.inputs.clear();
    // // Swap y and z key mappings
    // const oldKeysUp = this._camera.keysUp;
    // const oldKeysDown = this._camera.keysDown;
    // this._camera.keysUp = this._camera.keysUpward;
    // this._camera.keysDown = this._camera.keysDownward;
    // this._camera.keysUpward = oldKeysUp;
    // this._camera.keysDownward = oldKeysDown;

    // Create the terrain
    this._terrainManager = new TerrainManager(
      this._scene,
      this.movePlayerToStartingPosition.bind(this),
      this.getTilesPlayerTouching.bind(this),
      this.save.bind(this)
    );

    // Create the player character
    this._player = new PlayerCharacter(
      "player",
      this._scene,
      this._camera,
      this._terrainManager.collisionMap,
      this.isCollidingWithNpc.bind(this),
      this.npcLookup.bind(this),
      this.onPlayerDied.bind(this)
    );

    this._npcs = [];

    // Autosave
    setInterval(() => {
      this.save.bind(this)(false);
    }, 5000);

    // Gamepad inputs
    var gamepadManager = new BABYLON.GamepadManager();
    gamepadManager.onGamepadConnectedObservable.add((gamepad, state) => {
      if (gamepad instanceof BABYLON.Xbox360Pad) {
        //Xbox button down/up events
        gamepad.onButtonDownObservable.add((button, state) => {
          this._player.gamePadButtonDown(BABYLON.Xbox360Button[button]);
        });
        gamepad.onButtonUpObservable.add((button, state) => {
          this._player.gamePadButtonUp(BABYLON.Xbox360Button[button]);
        });

        //Stick events
        gamepad.onleftstickchanged((values) => {
          this._player.leftStickChanged(values);
        });
      }
    });
  }

  private getTilesPlayerTouching(): BABYLON.Vector2[] {
    return this._player.getTilesCharacterIsTouching();
  }

  public async initialize(): Promise<void> {
    await this._terrainManager.initialize();

    // Load the saved game
    this.loadSaveGame();
  }

  public tick(): void {
    const timeCorrectionFactor = this.engine.getDeltaTime() / (1000 / targetFrameRate);
    this._player.tick(timeCorrectionFactor);
    for (const npc of this._npcs) {
      npc.tick(timeCorrectionFactor);
    }
  }

  public keyDown(keyEvent: KeyboardEvent): boolean {
    switch (keyEvent.key) {
      case "F10":
        this.save(true);
        return true;
      case "r":
        this.startNewGame();
        return true;
      // case "Home":
      //   this._terrainManager!.resetCamera();
      //   return true;
      case "m":
        this.createNewMonster();
        return true;
      case "`":
        this._scene.debugLayer.show();
        return true;
    }

    return this._player.keyDown(keyEvent);
  }

  public keyUp(keyEvent: KeyboardEvent): boolean {
    return this._player.keyUp(keyEvent);
  }

  public click(mouseEvent: MouseEvent): boolean {
    return this._terrainManager.click(mouseEvent);
  }

  public pointerDown(mouseEvent: MouseEvent): boolean {
    return this._terrainManager.pointerDown(mouseEvent);
  }

  public pointerUp(mouseEvent: MouseEvent): boolean {
    return this._terrainManager.pointerUp(mouseEvent);
  }

  public pointerMove(mouseEvent: MouseEvent): boolean {
    return this._terrainManager.pointerMove(mouseEvent);
  }

  private movePlayerToStartingPosition = (gridX: number, gridY: number): void => {
    this._player!.moveToGridPosition(gridX, gridY);
  };

  private startNewGame(): void {
    this._saveGame = new SaveGame();
    this.loadSaveGame();
  }

  private loadSaveGame(): void {
    this._terrainManager.loadGame(this._saveGame);
    this.movePlayerToStartingPosition(this._saveGame.characterX!, this._saveGame.characterY!);
  }

  private save(tellUser: boolean = false): void {
    const characterPosition = this._player.gridPosition;
    this._saveGame.characterX = characterPosition.x;
    this._saveGame.characterY = characterPosition.y;
    this._saveGame.saveToLocalStorage();

    if (tellUser) {
      alert("Game saved");
    }
  }

  private nextNpcId: number = 0;
  private createNewMonster(): void {
    const newNpc = new MonsterCharacter(
      "npc" + this.nextNpcId++,
      redKnightCharacterData,
      this._scene,
      this._camera,
      this._terrainManager.collisionMap,
      this._player,
      this.isCollidingWithPlayer.bind(this),
      this.onMonsterDied.bind(this)
    );
    newNpc.moveToGridPosition(this._player.gridPosition.x + 1, 60);
    this._npcs.push(newNpc);
  }

  private npcLookup(whoIsAsking: Character, x: number, y: number): Character[] {
    const characters: Character[] = [];
    for (const npc of this._npcs) {
      if (npc !== whoIsAsking && npc.isCharacterTouchingPoint(x, y)) {
        characters.push(npc);
      }
    }
    return characters;
  }

  private isCollidingWithNpc(whoIsAsking: Character, x: number, y: number): boolean {
    for (const npc of this._npcs) {
      if (npc !== whoIsAsking && npc.isCharacterTouchingPoint(x, y)) {
        return true;
      }
    }
    return false;
  }

  private isCollidingWithPlayer(whoIsAsking: Character, x: number, y: number): boolean {
    if (whoIsAsking !== this._player && this._player.isCharacterTouchingPoint(x, y)) {
      return true;
    }
    return false;
  }

  private onPlayerDied(character: Character): void {
    // Too bad
  }

  private onMonsterDied(character: Character): void {
    const index = this._npcs.indexOf(character);
    this._npcs.splice(index, 1);
  }
}
