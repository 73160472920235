export function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function clamp(min: number, max: number, value: number): number {
  return Math.min(max, Math.max(min, value));
}

export type Rect = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};
