import * as BABYLON from "@babylonjs/core";
import { randomNumber } from "../utils";
import { Character, Direction } from "./character";
import { playerCharacterData } from "./data/player/playerCharacterData";

const cameraYTolerance: number = 4;
const cameraXTolerance: number = 4;
const attackReachPastCharacterEdge: number = 0.25;

export type CharacterCollisionCheck = (whoIsAsking: Character, x: number, y: number) => boolean;
export type CharacterLookup = (whoIsAsking: Character, x: number, y: number) => Character[];

export class PlayerCharacter extends Character {
  private _isHoldingJumpButton: boolean = false;

  constructor(
    name: string,
    scene: BABYLON.Scene,
    camera: BABYLON.UniversalCamera,
    collisionMap: boolean[][],
    private readonly isCollidingWithNpc: CharacterCollisionCheck,
    private readonly npcLookup: CharacterLookup,
    onDied: (character: Character) => void
  ) {
    super(name, playerCharacterData, scene, camera, collisionMap, onDied);
  }

  public tick(timeCorrectionFactor: number): void {
    // Jump if we are holding down the button if we can
    if (this._isHoldingJumpButton) {
      this.jump();
    }

    // Do all of the movement work
    super.tick(timeCorrectionFactor);

    // If the camera is too far away in the X direction, move it
    const cameraXOffset = this._camera.position.x - this._sprite!.position.x;
    if (cameraXOffset > cameraXTolerance) {
      // Camera is too far to the right of the sprite
      this._camera.position.x = this._sprite!.position.x + cameraXTolerance;
    }
    if (cameraXOffset < -1 * cameraXTolerance) {
      // Camera is too far to the left of the sprite
      this._camera.position.x = this._sprite!.position.x - cameraXTolerance;
    }

    // If the camera is too far away in the Y direction, move it
    const cameraYOffset = this._camera.position.y - this._sprite!.position.y;
    if (cameraYOffset > cameraYTolerance) {
      // Camera is too far above the sprite
      this._camera.position.y = this._sprite!.position.y + cameraYTolerance;
    }
    if (cameraYOffset < -1 * cameraYTolerance) {
      // Camera is too far below the sprite
      this._camera.position.y = this._sprite!.position.y - cameraYTolerance;
    }
  }

  public keyDown(keyEvent: KeyboardEvent): boolean {
    switch (keyEvent.key) {
      case "Control":
        this._isHoldingRunButton = true;
        return true;
      case "Alt":
        this.tryAttack();
        return true;
      case "ArrowLeft":
        this._joystickPosition = -1;
        return true;
      case "ArrowRight":
        this._joystickPosition = 1;
        return true;
      case " ":
      case "w":
        this.jump();
        return true;
      case "p":
        this.addFloatAwayText(Math.floor(randomNumber(-200, -100)).toString(), "red");
        return true;
    }
    return false;
  }

  public keyUp(keyEvent: KeyboardEvent): boolean {
    switch (keyEvent.key) {
      case "Control":
        this._isHoldingRunButton = false;
        return true;
      case "ArrowLeft":
        this._joystickPosition = 0;
        return true;
      case "ArrowRight":
        this._joystickPosition = 0;
        return true;
    }
    return false;
  }

  public leftStickChanged(values: BABYLON.StickValues): void {
    this._joystickPosition = Math.abs(values.x) > 0.1 ? values.x : 0;
  }

  public gamePadButtonDown(button: string): void {
    switch (button) {
      case "X":
        this._isHoldingRunButton = true;
        break;
      case "A":
        this._isHoldingJumpButton = true;
        this.jump();
        break;
    }
  }

  public gamePadButtonUp(button: string): void {
    switch (button) {
      case "X":
        this._isHoldingRunButton = false;
        break;
      case "A":
        this._isHoldingJumpButton = false;
        break;
      case "B":
        this.tryAttack();
        break;
    }
  }

  protected isCollidingWithAnotherCharacter(x: number, y: number): boolean {
    return this.isCollidingWithNpc(this, x, y);
  }

  protected hitHorizontalObstacle(): void {
    this.smallJump();
  }

  private tryAttack(): void {
    const attackXDirection = this._facing === Direction.Left ? -1 : 1;
    const monstersInRangeOfAttack = this.npcLookup(
      this,
      this._sprite.position.x + attackXDirection * (this._characterData.gridWidth / 2 + attackReachPastCharacterEdge),
      this._sprite.position.y
    );
    for (const monster of monstersInRangeOfAttack) {
      monster.inflictDamage(Math.floor(randomNumber(100, 200)));
    }
  }
}
